import { useRouteMatch, useHistory } from 'react-router-dom'
import { useEffect, useLayoutEffect, useState } from 'react'
import usePrevious from '../api/hooks/usePrevious'

const withScrollRestoration = (Component) => {
  return props => {
    const [key, setKey] = useState(null)
    const [shouldMount, setShouldMount] = useState(false)
    const match = useRouteMatch()
    const history = useHistory()
    const prevKey = usePrevious(key)
    const isShown = Boolean(match)

    useEffect(() => {
      if (isShown) {
        setShouldMount(true)
      }
    }, [isShown])

    useLayoutEffect(() => {
      if (isShown) {
        setKey(history.location.key)
      }
    }, [isShown, history])

    useLayoutEffect(() => {
      if (prevKey !== key) {
        window.scrollTo(0, 0)
      }
    }, [key, prevKey])

    if (!shouldMount) return null

    return (
      <div style={{ display: isShown ? 'block' : 'none' }} key={key}>
        <Component {...props} />
      </div>
    )
  }
}

export default withScrollRestoration
