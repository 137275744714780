import { useCallback, useMemo, useEffect, useState } from 'react'
import SessionStore from '../stores/SessionStore'
import UserActionCreators from '../actions/UserActionCreators'

const useMyLotSettings = () => {
  const [
    {
      user,
      isUpdating,
    },
    setState,
  ] = useState({
    user: SessionStore.user,
    isUpdating: false,
  })
  const { rooftop } = user

  const alertSettings = useMemo(() => {
    return {
      alertsDaysOnLotIsEnabled: rooftop.lot_vehicle_alerts_days_on_lot_is_enabled,
      alertsPercentOfMarketIsEnabled: rooftop.lot_vehicle_alerts_percent_of_market_is_enabled,
      alertsRetailSupplyIsEnabled: rooftop.lot_vehicle_alerts_retail_supply_is_enabled,
      alertsWholesaleProfitIsEnabled: rooftop.lot_vehicle_alerts_wholesale_profit_is_enabled,
      alertsRetailProfitIsEnabled: rooftop.lot_vehicle_alerts_retail_profit_is_enabled,
      alertsFirstPriceChangeIsEnabled: rooftop.lot_vehicle_alerts_first_price_change_is_enabled,
      alertsDaysOnLotThreshold: rooftop.lot_vehicle_alerts_days_on_lot_threshold,
      alertsPercentOfMarketHighThreshold: rooftop.lot_vehicle_alerts_percent_of_market_high_threshold,
      alertsPercentOfMarketLowThreshold: rooftop.lot_vehicle_alerts_percent_of_market_low_threshold,
      alertsRetailProfitThreshold: rooftop.lot_vehicle_alerts_retail_profit_threshold,
    }
  }, [
    rooftop.lot_vehicle_alerts_days_on_lot_is_enabled,
    rooftop.lot_vehicle_alerts_percent_of_market_is_enabled,
    rooftop.lot_vehicle_alerts_retail_supply_is_enabled,
    rooftop.lot_vehicle_alerts_wholesale_profit_is_enabled,
    rooftop.lot_vehicle_alerts_retail_profit_is_enabled,
    rooftop.lot_vehicle_alerts_first_price_change_is_enabled,
    rooftop.lot_vehicle_alerts_days_on_lot_threshold,
    rooftop.lot_vehicle_alerts_percent_of_market_high_threshold,
    rooftop.lot_vehicle_alerts_percent_of_market_low_threshold,
    rooftop.lot_vehicle_alerts_retail_profit_threshold,
  ])

  const lotInfo = useMemo(() => {
    return {
      lotName: rooftop.lot_name,
      lotUrl: rooftop.lot_url,
      lotCity: rooftop.lot_address?.city,
      lotState: rooftop.lot_address?.state,
    }
  }, [
    rooftop.lot_name,
    rooftop.lot_url,
    rooftop.lot_address?.city,
    rooftop.lot_address?.state,
  ])

  const regionSettings = useMemo(() => {
    return {
      zip: rooftop.region_zip,
      radius: rooftop.region_radius,
    }
  }, [
    rooftop.region_zip,
    rooftop.region_radius,
  ])

  const onboarding = useMemo(() => {
    return {
      apolloLinked: rooftop.lot_inventory_provider_apollo_linked,
      preferredLinked: rooftop.lot_inventory_provider_preferred_linked,
      isUninterestedInApollo: rooftop.is_uninterested_in_apollo,
      lotInventoryStatus: rooftop.lot_inventory_status,
      lotUrlReviewState: rooftop.user_requested_lot_url_review,
      userSubmittedLotUrl: rooftop.user_requested_lot_url,
      lotVehiclesExist: rooftop.lot_vehicles_exist,
    }
  }, [
    rooftop.lot_inventory_provider_apollo_linked,
    rooftop.lot_inventory_provider_preferred_linked,
    rooftop.is_uninterested_in_apollo,
    rooftop.lot_inventory_status,
    rooftop.user_requested_lot_url_review,
    rooftop.user_requested_lot_url,
    rooftop.lot_vehicles_exist,
  ])

  const urlReviewStateMessage = useMemo(() => {
    switch (onboarding.lotUrlReviewState) {
      case 'pending':
        return `Your requested website url change (${onboarding.userSubmittedLotUrl}) is under review.`
      case 'rejected':
        return `Your requested website url change (${onboarding.userSubmittedLotUrl}) was rejected.`
      default:
        return null
    }
  }, [
    onboarding.lotUrlReviewState,
    onboarding.userSubmittedLotUrl,
  ])

  const lotInventoryStatusMessage = useMemo(() => {
    switch (onboarding.lotInventoryStatus) {
      case 'interested':
        return 'Your request to connect to your vehicle management system is pending.'
      default:
        return null
    }
  }, [onboarding.lotInventoryStatus])

  const onUserDataChanged = useCallback(() => {
    // FIXME: isUpdating flipped to false
    // when user data change, which can happen from unrelated actions.
    // This condition is probably more widespread (i.e. GlobalCostsEntryModal)
    setState(prev => ({
      ...prev,
      user: SessionStore.user,
      isUpdating: false,
    }))
  }, [])

  const update = useCallback(({
    zip,
    radius,
    lotName,
    userSubmittedLotUrl,
    alertsDaysOnLotIsEnabled,
    alertsPercentOfMarketIsEnabled,
    alertsRetailSupplyIsEnabled,
    alertsWholesaleProfitIsEnabled,
    alertsRetailProfitIsEnabled,
    alertsFirstPriceChangeIsEnabled,
    alertsDaysOnLotThreshold,
    alertsPercentOfMarketHighThreshold,
    alertsPercentOfMarketLowThreshold,
    alertsRetailProfitThreshold,
    isUninterestedInApollo,
    lotInventoryStatus,
  }) => {
    const params = {}
    if (zip) params.region_zip = zip
    if (radius) params.region_radius = radius
    if (lotName) params.lot_name = lotName
    if (userSubmittedLotUrl !== undefined) params.user_requested_lot_url = userSubmittedLotUrl
    if (alertsDaysOnLotIsEnabled !== undefined) params.lot_vehicle_alerts_days_on_lot_is_enabled = alertsDaysOnLotIsEnabled
    if (alertsPercentOfMarketIsEnabled !== undefined) params.lot_vehicle_alerts_percent_of_market_is_enabled = alertsPercentOfMarketIsEnabled
    if (alertsRetailSupplyIsEnabled !== undefined) params.lot_vehicle_alerts_retail_supply_is_enabled = alertsRetailSupplyIsEnabled
    if (alertsWholesaleProfitIsEnabled !== undefined) params.lot_vehicle_alerts_wholesale_profit_is_enabled = alertsWholesaleProfitIsEnabled
    if (alertsRetailProfitIsEnabled !== undefined) params.lot_vehicle_alerts_retail_profit_is_enabled = alertsRetailProfitIsEnabled
    if (alertsFirstPriceChangeIsEnabled !== undefined) params.lot_vehicle_alerts_first_price_change_is_enabled = alertsFirstPriceChangeIsEnabled
    if (alertsDaysOnLotThreshold !== undefined) params.lot_vehicle_alerts_days_on_lot_threshold = alertsDaysOnLotThreshold
    if (alertsPercentOfMarketHighThreshold !== undefined) params.lot_vehicle_alerts_percent_of_market_high_threshold = alertsPercentOfMarketHighThreshold
    if (alertsPercentOfMarketLowThreshold !== undefined) params.lot_vehicle_alerts_percent_of_market_low_threshold = alertsPercentOfMarketLowThreshold
    if (alertsRetailProfitThreshold !== undefined) params.lot_vehicle_alerts_retail_profit_threshold = alertsRetailProfitThreshold
    if (isUninterestedInApollo !== undefined) params.is_uninterested_in_apollo = isUninterestedInApollo
    if (lotInventoryStatus !== undefined) params.lot_inventory_status = lotInventoryStatus
    setState(prev => ( {...prev, isUpdating: true }))
    UserActionCreators.updateRooftop(params)
  }, [])

  useEffect(() => {
    const userDataChangedEv = 'user_data_changed'
    SessionStore.on(userDataChangedEv, onUserDataChanged)

    return () => {
      SessionStore.removeListener(userDataChangedEv, onUserDataChanged)
    }
  }, [onUserDataChanged])

  return [
    {
      lotInfo,
      regionSettings,
      alertSettings,
      onboarding,
      urlReviewStateMessage,
      lotInventoryStatusMessage,
    },
    update,
    isUpdating,
  ]
}

export default useMyLotSettings
