import { Link } from 'react-router-dom'
import SessionStore from "../../api/stores/SessionStore"
import AppConstants from '../../api/constants/AppConstants'
import useMyLotSettings from '../../api/hooks/useMyLotSettings'

const ZipCodeDiffersMessage = ({style}) => {
  const [
    {
      regionSettings: {
        zip
      },
    },
  ] = useMyLotSettings()

  if (zip !== SessionStore.userZipCode()) {
    return (
      <div style={{...style}}>Regional data is based on ZIP code {zip} as defined in <Link to='/lot-sense/settings'>{AppConstants.providerNames['my_lot']} settings</Link>.</div>
    )
  }
  return null
}

export default ZipCodeDiffersMessage
