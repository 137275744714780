import React from 'react'
import { Popover, PopoverBody, Button } from 'reactstrap'
import ValuationStore from '../../api/stores/ValuationStore'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import Spinner from '../common/Spinner'
import ValuationFunctions from '../../api/constants/ValuationFunctions'
import AppConstants from '../../api/constants/AppConstants'
import Formatters from '../../api/constants/Formatters'
import pluralize from 'pluralize'
import moment from 'moment'
import BookoutIcon from '../common/icons/BookoutIcon'

export default class ValuationProviderCard extends React.Component {
  state = {
    isShowingProviderChoicesPopover: false,
    isSavingChangedChoice: false,
    isLoadingLimitedProvider: false
  }

  constructor(props) {
    super(props)
    this.isShared = ValuationStore.isSharedValuation
  }

  componentDidMount() {
    this.clientID = this.props.clientID
    this.addListeners()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.clientID !== prevProps.clientID) {
      this.removeListeners()
      this.clientID = this.props.clientID
      this.addListeners()
    }
  }

  componentWillUnmount() {
    this.removeListeners()
  }

  addListeners = () => {
    ValuationStore.on(`valuation_change_${this.clientID}`, this.valuationChanged)
  }

  removeListeners = () => {
    ValuationStore.removeListener(`valuation_change_${this.clientID}`, this.valuationChanged)
  }

  valuationChanged = () => {
    this.setState({
      isSavingChangedChoice: false,
      isLoadingLimitedProvider: false
    })
  }

  toggleChoicesPopover = () => {
    const { isShowingProviderChoicesPopover } = this.state
    this.setState({isShowingProviderChoicesPopover: !isShowingProviderChoicesPopover})
  }

  changeChoice = (uid) => {
    const currentChoiceUID = this.props.currentChoiceUID
    if (currentChoiceUID && currentChoiceUID === uid) {
      return
    }
    this.setState({
      isShowingProviderChoicesPopover: false,
      isSavingChangedChoice: true
    })
    ValuationActionCreators.updateValuationProviderChoice(this.props.vehicleUUID, this.props.providerKey, uid, this.props.clientID)
  }

  limitedProviderFetch = () => {
    // For mini block providers, run the valuation
    this.setState({
      isLoadingLimitedProvider: true
    })
    setTimeout(() => {
      ValuationActionCreators.loadLimitedProvidersValuation(this.props.vehicleUUID, { providers: [this.props.providerKey] }, this.props.clientID)
    }, 50)

  }

  renderChildren(isEmptyData, limitedProviderNeedsFetch) {
    if (this.props.error || (isEmptyData && !limitedProviderNeedsFetch)) {
      return (<div className="secondary-text">No appraisal data available</div>)
    } else if (!isEmptyData) {
      return this.props.children
    }
  }


  render() {
    const providerData = this.props.providerData
    const { title, subtitle, showAdjustmentMessage, messages, hasError } = this.props
    const { isShowingProviderChoicesPopover, isSavingChangedChoice, isLoadingLimitedProvider } = this.state
    const fullChoices = this.props.fullChoices && this.props.fullChoices.length > 0 ? this.props.fullChoices : null
    const currentChoiceUID = this.props.currentChoiceUID
    const systemData = this.props.systemData
    const isEmptyData = this.props.forceHasData === true ? false : ValuationFunctions.valuationHasEmptyData(providerData)
    const limitedProviderNeedsFetch = ValuationFunctions.isLimitedValuationNeedsFetch(providerData)
    const providerMileage = this.props.providerMileage
    const valuationMileage = this.props.valuationMileage
    const cachedMileageDiffers = providerMileage && valuationMileage && providerMileage !== valuationMileage
    const changesLocked = systemData && systemData.changes_locked === true
    const cachedDataAge = systemData && systemData.cached_at ? moment().diff(systemData.cached_at, 'days') : null
    const cacheIsStale = cachedDataAge && cachedDataAge > 2

    const formattedDataFromDateString = (providerData && providerData.description && providerData.description.values_date && !systemData) ? moment(providerData.description.values_date).format('MMM Do') : null
    const formattedTitle = title.replace("®", "<sup>&reg;</sup>")

    return (
      <section className="card valuation-section" id={this.props.providerKey}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <div style={{flexGrow: 1, flexShrink: 1}}>
            <h2 dangerouslySetInnerHTML={{__html: formattedTitle}}></h2>
          </div>
          {
            this.props.accessoryView &&
            this.props.accessoryView
          }
        
        </div>
        {
          this.props.providerKey === 'nada' &&
          <div style={{fontWeight: 'bold', marginBottom: '10px', fontStyle: 'italic'}}>Formerly called NADA Official Used Car Guide®</div>
        }
        {
          !isSavingChangedChoice && !isLoadingLimitedProvider &&
          <div>
            {
              subtitle && hasError === false && this.isShared === false && !global.isPartnerPreview && fullChoices !== null && !changesLocked && !limitedProviderNeedsFetch &&
              <div>
                <button id={`pop-${this.props.providerKey}`} className="action-link">{subtitle}</button>
                <Popover placement="bottom" isOpen={isShowingProviderChoicesPopover} trigger="legacy" target={`pop-${this.props.providerKey}`} toggle={this.toggleChoicesPopover}>
                  <PopoverBody className='popover-med' style={{maxHeight: '400px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', maxHeight: '400px'}}>
                      <div style={{height: '100%', maxHeight: '330px', flexGrow: 1, flexShrink: 1, overflow: 'scroll'}}>
                      {
                        fullChoices.map((choice) => {
                          return (
                            <a href="#select-Configuration" className="popover-item" onClick={(e) => {e.preventDefault(); this.changeChoice(choice.uid)}} key={`assign-config-${choice.uid}`}>
                              <div style={{opacity: currentChoiceUID && currentChoiceUID === choice.uid ? '0.4' : '1.0'}}>{choice['display_choice']} {choice['total_transaction_count'] ? `(${choice['total_transaction_count']})` : ''}</div>
                            </a>
                          )
                        })
                      }
                      </div>

                      <div className='popover-controls'>
                        <Button color="secondary" onClick={this.toggleChoicesPopover}>Cancel</Button>
                      </div>
                    </div>
                  </PopoverBody>
                </Popover>
              </div>
            }
            {
              subtitle && hasError === false && !limitedProviderNeedsFetch && (!fullChoices || this.isShared || global.isPartnerPreview || changesLocked === true) &&
              <h3>{subtitle}</h3>
            }

            {
              global.isPartnerPreview &&
              <div style={{fontWeight: 'bold', marginBottom: '5px'}}>Trim can be modified with a registered Carbly account.</div>
            }

            {
              !global.isPartnerPreview && !global.isExtensionMode && !this.isShared && isEmptyData === false && (showAdjustmentMessage === true || formattedDataFromDateString) &&
              <h3>{formattedDataFromDateString ? `Data from ${formattedDataFromDateString}` : ''}{formattedDataFromDateString && showAdjustmentMessage === true ? ' - ' : ''}{showAdjustmentMessage === true ? AppConstants.adjustmentsMessage : ''}
              { this.props.changeableDateComponent }
              </h3>
            }

            {
              messages && messages.length > 0 &&
              <div style={{marginBottom: '10px'}}>
              {
                messages.map((message) => {
                  return (
                    <div style={{padding: 4, backgroundColor: '#eee', borderRadius: 6, marginTop: 10, color: '#555'}} key={`msg-${message.substring(0,8)}`}>{message}</div>
                  )
                })
              }
              </div>
            }




            {
              systemData && systemData.cached_at && !this.isShared &&
              <div>Data from {moment(systemData.cached_at).format('MMM Do, YYYY')}</div>
            }
            {
              limitedProviderNeedsFetch && !this.isShared &&
              <div style={{marginTop: '10px'}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  {
                    systemData?.number_left > 0 &&
                    <Button color="primary" onClick={this.limitedProviderFetch} style={{marginRight: '10px'}}>Run Valuation</Button>
                  }
                  <div>
                    {systemData?.number_left} remaining in this billing period. Quota resets in {pluralize('day', systemData.days_left, true)}.
                    {
                      systemData?.number_left === 0 &&
                      <div>If you'd like to upgrade your plan, give us a call at {AppConstants.phoneNumber}</div>
                    }
                  </div>
                </div>
              </div>
            }

            {
              limitedProviderNeedsFetch && this.isShared &&
              <div>Valuation for this provider was not performed. If it is needed, please contact the person who shared this report with you.</div>
            }



            {
              systemData && !this.isShared && (cacheIsStale || changesLocked || cachedMileageDiffers) &&
              <div style={{backgroundColor: '#f9fadd', margin: '10px 0', padding: '10px'}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <Button onClick={this.limitedProviderFetch} style={{marginRight: '10px'}}>Re-Run this valuation</Button>

                  <div>
                    {
                      cachedMileageDiffers &&
                      <div style={{textAlign: 'center', color: 'red'}}>This valuation was run with {Formatters.formatThousands(providerMileage)} miles, but the vehicle is specified to have {Formatters.formatThousands(valuationMileage)} miles</div>
                    }
                    {
                      cacheIsStale === true && cachedMileageDiffers !== true && changesLocked !== true &&
                      <div>Data is {cachedDataAge} days old</div>
                    }
                    {
                      changesLocked && !cachedMileageDiffers &&
                      <div>This data {cacheIsStale === true ? `is ${cachedDataAge} days old and ` : ''}is no longer editable. Re-run the valuation to enable changes.</div>
                    }

                    <div style={{fontSize: '0.9rem'}}>You have {systemData?.number_left} remaining, and your quota resets in {pluralize('day', systemData.days_left, true)}</div>
                  </div>
                </div>
              </div>
            }



            { this.renderChildren(isEmptyData, limitedProviderNeedsFetch) }

            {
            this.props.onBookout && this.isShared === false && !global.isPartnerPreview && !isEmptyData &&
              <div style={{textAlign: 'right', marginTop: '10px'}}>
                <a href="#bookout" onClick={(e) => {
                  e.preventDefault()
                  if (this.props.onBookout) {
                    this.props.onBookout()
                  }
                }}><BookoutIcon color='currentColor' width='1em' height='1em' /> Bookout {title}</a>
              </div>
            }
          </div>
        }

        {
          (isSavingChangedChoice === true || isLoadingLimitedProvider === true) &&
          <div style={{margin: '2rem 0'}}>
            <Spinner />
          </div>
        }
      </section>
    )


  }
}
