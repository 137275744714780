import { useCallback, useMemo, useState } from 'react'
import Header from './Header'
import { CustomInput } from 'reactstrap'
import RegionOptionsModal from '../../components/retail/RegionOptionsModal'
import { regionalReportOptions } from '../../components/retail/constants'
import useMyLotSettings from '../../api/hooks/useMyLotSettings'
import AlertsOptionsModal from '../../components/myLot/AlertsOptionsModal'
import RequestWebsiteUrlChangeButton from './RequestWebsiteUrlChangeButton'
import pick from 'lodash/pick'
import pluralize from 'pluralize'
import OnboardingPrompts from './OnboardingPrompts'
import AppConstants from '../../api/constants/AppConstants'
import Formatters from '../../api/constants/Formatters'
import UserActionCreators from '../../api/actions/UserActionCreators'

// Fixme alertsFirstPriceChangeIsEnabled messaging

const Settings = () => {
  const [
    {
    regionSettings: {
      zip,
      radius,
    },
    lotInfo: {
      lotUrl,
    },
    onboarding: {
      lotUrlReviewState,
      preferredLinked,
      apolloLinked,
    },
    lotInventoryStatusMessage,
    urlReviewStateMessage,
    alertSettings,
  },
  updateSettings,
  isUpdatingSettings,
] = useMyLotSettings()

  UserActionCreators.createMetric(AppConstants.metricNames['my_lot_view_settings'])

  const alertsOptions = useMemo(() => {
    return pick(alertSettings, [
      'alertsDaysOnLotThreshold',
      'alertsPercentOfMarketHighThreshold',
      'alertsPercentOfMarketLowThreshold',
      'alertsRetailProfitThreshold',
    ])
  }, [alertSettings])

  const [isShowingRegionOptionsModal, setIsShowingRegionOptionsModal] = useState(false)
  const [isShowingAlertsOptionsModal, setIsShowingAlertsOptionsModal] = useState(false)

  const onRegionOptionsSave = useCallback(({ radius, zip }) => {
    setIsShowingRegionOptionsModal(false)
    updateSettings({ radius, zip })
  }, [])

  const onRegionOptionsModalClose = useCallback(() => {
    setIsShowingRegionOptionsModal(false)
  }, [])

  const onAlertsOptionsSave = useCallback(options => {
    setIsShowingAlertsOptionsModal(false)
    updateSettings(options)
  }, [])

  const onAlertsOptionsModalClose = useCallback(() => {
    setIsShowingAlertsOptionsModal(false)
  }, [])

  const onRegionOptionsClick = useCallback(() => {
    setIsShowingRegionOptionsModal(true)
  }, [])

  const onAlertsOptionsClick = useCallback(() => {
    setIsShowingAlertsOptionsModal(true)
  }, [])

  const onClickCancelRequestWebsiteUrlChange = useCallback(e => {
    e.preventDefault()
    if (confirm('Are you sure you want to cancel the requested website url change?')) {
      updateSettings({ userSubmittedLotUrl: null })
    }
  }, [updateSettings])

  const selectedRadiusText = useMemo(() => {
    return regionalReportOptions.radius.find(item => item.value === radius).label
  }, [radius])

  const alertsEnabled = useMemo(() => {
    return {
      alertsDaysOnLotIsEnabled: {
        value: alertSettings.alertsDaysOnLotIsEnabled,
        label: 'Days on lot enabled',
      },
      alertsPercentOfMarketIsEnabled: {
        value: alertSettings.alertsPercentOfMarketIsEnabled,
        label: 'Percent of market enabled',
      },
      alertsRetailSupplyIsEnabled: {
        value: alertSettings.alertsRetailSupplyIsEnabled,
        label: 'Retail supply enabled',
      },
      alertsWholesaleProfitIsEnabled: {
        value: alertSettings.alertsWholesaleProfitIsEnabled,
        label: 'Wholesale profit enabled',
      },
      alertsRetailProfitIsEnabled: {
        value: alertSettings.alertsRetailProfitIsEnabled,
        label: 'Retail profit enabled',
      },
      alertsFirstPriceChangeIsEnabled: {
        value: alertSettings.alertsFirstPriceChangeIsEnabled,
        label: 'First price drop enabled',
      },
    }
  }, [alertSettings])

  return (
    <>
      <div>
        <Header title={`${AppConstants.providerNames['my_lot']} Settings`} />

        {
          lotInventoryStatusMessage &&
          <div>{lotInventoryStatusMessage}</div>
        }

        <OnboardingPrompts showDismissAction={false} showManualVINEntry={false} />

        <div className='my-lot-settings-c'>
          {
            // FIXME: What if interested in preferred provider?
            ((apolloLinked && !preferredLinked) || lotUrlReviewState !== null) &&
            <div>
              <h2>Website Connection</h2>
              {
                lotUrl &&
                <div>
                  <a href={lotUrl} target='_blank' rel='noreferrer' style={{ color: 'inherit' }}>{lotUrl}</a>
                </div>
              }

              {
                lotUrlReviewState === null &&
                <div>
                  <RequestWebsiteUrlChangeButton buttonText='Need to change your website?'/>
                </div>
              }

              {
                urlReviewStateMessage &&
                <div>
                  <span>{urlReviewStateMessage}</span>
                </div>
              }

              {
                lotUrlReviewState !== null &&
                <div>
                  <a href='#cancel-request-url-change' onClick={onClickCancelRequestWebsiteUrlChange} className={isUpdatingSettings ? 'disabled-anchor' : ''}>
                    Cancel requested website change
                  </a>
                </div>
              }
            </div>
          }
          <div>
            <h2>Region Settings</h2>
            Includes all dealers within {selectedRadiusText} of zipcode {zip}. <a href="#region-options" onClick={(e) => {
              e.preventDefault()
              onRegionOptionsClick()
              }}>Edit region</a>
          </div>
          <div>
            <h2>Vehicles Needing Attention Settings</h2>
            <p>Carbly will show vehicles on your lot that have met or failed to meet certain conditions.  You can customize the conditions here.</p>
            <div>
              {
                Object.keys(alertsEnabled).map(key => (
                  <div key={key} className='inline-checkbox' style={{marginBottom: '10px'}}>
                    <CustomInput
                      id={key}
                      type='switch'
                      disabled={isUpdatingSettings}
                      checked={alertsEnabled[key].value}
                      label={alertsEnabled[key].label}
                      onChange={() => updateSettings({ [key]: !alertsEnabled[key].value })}
                    />
                    {
                      key === 'alertsRetailSupplyIsEnabled' &&
                      <span className='secondary-text'>Be notified when inventory in your local market is unusally high or low for vehicles with the same year, make, model, and trim.</span>
                    }
                    {
                      key === 'alertsRetailProfitIsEnabled' &&
                      <span className='secondary-text'>Be notified when your profit potential is getting low or is no longer achievable.</span>
                    }
                    {
                      key === 'alertsWholesaleProfitIsEnabled' &&
                      <span className='secondary-text'>Be notified when it may possible to exit via wholesale at break even or profit.</span>
                    }
                    {
                      key === 'alertsDaysOnLotIsEnabled' &&
                      <span className='secondary-text'>Be notified when a vehicle has exceeded your threshold for days on lot.</span>
                    }
                    {
                      key === 'alertsPercentOfMarketIsEnabled' &&
                      <span className='secondary-text'>Be notified when vehicles are priced above or below the market range.</span>
                    }
                    {
                      key === 'alertsFirstPriceChangeIsEnabled' &&
                      <span className='secondary-text'>Be notified when its time to drop the initial asking price.</span>
                    }

                  </div>
                ))
              }
            </div>


            <div style={{fontWeight: 'bold', marginTop: '20px'}}>Conditions Thresholds</div>
            <p>For each of the above conditions, you can customize the thresholds for vehicles needing attention.  Here are the thresholds.</p>
            <div>Days on lot threshold: <strong>{pluralize('day', alertSettings.alertsDaysOnLotThreshold, true)}</strong></div>
            <div>Percent of market range: <strong>{alertSettings.alertsPercentOfMarketLowThreshold}%</strong> to <strong>{alertSettings.alertsPercentOfMarketHighThreshold}%</strong></div>
            <div>Retail profit threshold: <strong>{alertSettings.alertsRetailProfitThreshold == null ? 'none' : Formatters.formatCurrency(alertSettings.alertsRetailProfitThreshold)}</strong></div>

            <div style={{marginTop: '20px'}}>
              <a href="#alert-options" onClick={(e) => {
                e.preventDefault()
                onAlertsOptionsClick()
              }}>Change these thresholds</a>
            </div>
          </div>
        </div>
      </div>
      {
        isShowingRegionOptionsModal &&
        <RegionOptionsModal
          title='Region Settings'
          radius={radius}
          zip={zip}
          radiusOptions={regionalReportOptions.radius}
          onSave={onRegionOptionsSave}
          onClose={onRegionOptionsModalClose}
        />
      }
      {
        isShowingAlertsOptionsModal &&
        <AlertsOptionsModal
          title='Conditions Thresholds'
          values={alertsOptions}
          onSave={onAlertsOptionsSave}
          onClose={onAlertsOptionsModalClose}
        />
      }
    </>
  )
}

export default Settings
