import RequestWebsiteUrlChangeButton from './RequestWebsiteUrlChangeButton'
import ConnectPreferredLotInventoryProviderButton from './ConnectPreferredLotInventoryProviderButton'
import useMyLotSettings from '../../api/hooks/useMyLotSettings'
import { Link } from 'react-router-dom'

const OnboardingPrompts = ({ showDismissAction = true, showManualVINEntry = false, showLearnMore = false, className }) => {
  const [
    {
      onboarding: {
        isUninterestedInApollo,
        lotInventoryStatus,
        preferredLinked,
        apolloLinked,
        lotUrlReviewState,
      },
    },
    updateSettings,
    isUpdatingSettings,
  ] = useMyLotSettings()

  const showPreferredProviderPrompt = lotInventoryStatus === 'unknown'
  const showApolloPrompt = !isUninterestedInApollo && !apolloLinked && lotUrlReviewState === null && lotInventoryStatus !== 'interested' && !preferredLinked

  if (!showApolloPrompt && !showPreferredProviderPrompt) return null

  let promptsCount = 0
  if (showPreferredProviderPrompt) promptsCount++
  if (showApolloPrompt) promptsCount++
  if (showManualVINEntry) promptsCount++

  return (
    <div className={`my-lot-onboarding-widgets ${className} ${promptsCount === 1 ? 'my-lot-onboarding-widgets-wide' : ''}`}>
      {
        showPreferredProviderPrompt &&
        <div>
          <div className='widget-contents-c'>
            <div className='onboarding-widget-body'>
              <div className='icon'>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9"/>
                  <path fillRule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z"/>
                </svg>
              </div>
              <div>
                <h3>Connect your inventory management system</h3>
                <p>Automatically sync your current inventory to Carbly for more powerful and accurate lot insights and vehicle recommendations.</p>
                <p>The preferred way of getting your inventory into Carbly.</p>
              </div>
            </div>
            <div className='onboarding-widget-footer'>
              <div className='split-row'>
                {
                  showDismissAction &&
                  <button className='link' disabled={isUpdatingSettings} onClick={() => updateSettings({ lotInventoryStatus: 'uninterested' })}>No thanks</button>
                }
                {
                  showLearnMore ?
                  <Link to='/lot-sense' className='link'>Learn more</Link> :
                  <ConnectPreferredLotInventoryProviderButton buttonText='Connect my system' />
                }
              </div>
            </div>
          </div>
        </div>
      }

      {
        showApolloPrompt &&
        <div>
          <div className='widget-contents-c'>
          <div className='onboarding-widget-body'>
            <div className='icon'>
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" viewBox="0 0 16 16">
                <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m7.5-6.923c-.67.204-1.335.82-1.887 1.855q-.215.403-.395.872c.705.157 1.472.257 2.282.287zM4.249 3.539q.214-.577.481-1.078a7 7 0 0 1 .597-.933A7 7 0 0 0 3.051 3.05q.544.277 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9 9 0 0 1-1.565-.667A6.96 6.96 0 0 0 1.018 7.5zm1.4-2.741a12.3 12.3 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332M8.5 5.09V7.5h2.99a12.3 12.3 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176 1.914.399 2.741A13.6 13.6 0 0 1 7.5 10.91V8.5zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741zm-3.282 3.696q.18.469.395.872c.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81.03-1.577.13-2.282.287zm.11 2.276a7 7 0 0 1-.598-.933 9 9 0 0 1-.481-1.079 8.4 8.4 0 0 0-1.198.49 7 7 0 0 0 2.276 1.522zm-1.383-2.964A13.4 13.4 0 0 1 3.508 8.5h-2.49a6.96 6.96 0 0 0 1.362 3.675c.47-.258.995-.482 1.565-.667m6.728 2.964a7 7 0 0 0 2.275-1.521 8.4 8.4 0 0 0-1.197-.49 9 9 0 0 1-.481 1.078 7 7 0 0 1-.597.933M8.5 11.909v3.014c.67-.204 1.335-.82 1.887-1.855q.216-.403.395-.872A12.6 12.6 0 0 0 8.5 11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.96 6.96 0 0 0 14.982 8.5h-2.49a13.4 13.4 0 0 1-.437 3.008M14.982 7.5a6.96 6.96 0 0 0-1.362-3.675c-.47.258-.995.482-1.565.667.248.92.4 1.938.437 3.008zM11.27 2.461q.266.502.482 1.078a8.4 8.4 0 0 0 1.196-.49 7 7 0 0 0-2.275-1.52c.218.283.418.597.597.932m-.488 1.343a8 8 0 0 0-.395-.872C9.835 1.897 9.17 1.282 8.5 1.077V4.09c.81-.03 1.577-.13 2.282-.287z"/>
              </svg>
            </div>
            <div>
              <h3>Link your website</h3>
              <p>Carbly can automatically import your inventory from your website.</p>
              <p>Less accurate than connecting directly to your vehicle management system.</p>
            </div>
          </div>
          
          <div className='onboarding-widget-footer'>
            <div className='split-row'>
                {
                  showDismissAction &&
                  <button className='link' disabled={isUpdatingSettings} onClick={() => updateSettings({ isUninterestedInApollo: true })}>No thanks</button>
                }
                {
                  showLearnMore ?
                  <Link to='/lot-sense' className='link'>Learn more</Link> :
                  <RequestWebsiteUrlChangeButton buttonText='Connect my website' />
                }
            </div>
          </div>
        </div>
        </div>
      }

      {
        showManualVINEntry &&
        <div>
        <div className='widget-contents-c'>
          <div className='onboarding-widget-body'>
            <div className='icon'>
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" viewBox="0 0 16 16">
                <path d="M2 2h2v2H2z"/>
                <path d="M6 0v6H0V0zM5 1H1v4h4zM4 12H2v2h2z"/>
                <path d="M6 10v6H0v-6zm-5 1v4h4v-4zm11-9h2v2h-2z"/>
                <path d="M10 0v6h6V0zm5 1v4h-4V1zM8 1V0h1v2H8v2H7V1zm0 5V4h1v2zM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8zm0 0v1H2V8H1v1H0V7h3v1zm10 1h-1V7h1zm-1 0h-1v2h2v-1h-1zm-4 0h2v1h-1v1h-1zm2 3v-1h-1v1h-1v1H9v1h3v-2zm0 0h3v1h-2v1h-1zm-4-1v1h1v-2H7v1z"/>
                <path d="M7 12h1v3h4v1H7zm9 2v2h-3v-1h2v-1z"/>
              </svg>
            </div>
            <div>
              <h3>Manually enter vehicles by VIN</h3>
              <p>Add and remove vehicles yourself by entering VIN numbers by using the <Link to='/vehicles'>Vehicles Tab</Link>.  Add a vehicle to your lot, or simply open an existing vehicle and assign it to your lot.</p>
            </div>
          </div>
          
        </div>
      </div>
      }
    </div>
  )
}

export default OnboardingPrompts
