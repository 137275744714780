import { useState, memo } from 'react'
import ListingsList from './ListingsList'

const ListingsListWithHeader = memo(({ regionalListingsParams, order = 'mileage', showYMMT = true }) => {

  const [sortOrder, setSortOrder] = useState(order)

  // Reusable sort link component

  const SortLink = ({ order, text, className }) => {
    const isSorted = sortOrder.includes(order)
    const sortSymbol = sortOrder === order ? '▲' : '▼'
    if (sortOrder === order) {
      order = `-${order}`
    }
    return (
      <a href="#sort" className={className} style={{fontWeight: isSorted ? 'bold' : 'normal'}} onClick={(e) => {e.preventDefault(); setSortOrder(order)}}>{text}{isSorted ? sortSymbol : null}</a>
    )
  }

  return (
    <div>
      <div style={{position: 'sticky', top: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#eee'}}>
        <div className='listings-list-photo' style={{width: '100px', height: 'inherit !important', marginRight: '10px', flexGrow: 0, flexShrink: 0}}></div>
        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', flexGrow: 1, flexShrink: 1}} className='listings-sort-header'>
          <SortLink order='price' text='Price' className='listings-list-price' />
          <SortLink order='mileage' text='Mileage' className='listings-list-mileage' />
          <SortLink order='distance' text='Distance' className='listings-list-distance' />
          <SortLink order='days_on_lot' text='DoL' className='listings-list-dol' />
          <SortLink order='num_price_changes' text='Changes' className='listings-list-price-changes' />
        </div>
      </div>

      <ListingsList regionalListingsParams={regionalListingsParams} showYMMT={showYMMT} showValueLabels={false} order={sortOrder} showDistance />
    </div>
  )
})

export default ListingsListWithHeader
