import React from 'react'
import platformFunctions from '../platformFunctions'
import AccountSettings from '../components/settings/AccountSettings'

export default class AccountPage extends React.Component {
  componentDidMount() {
    platformFunctions.logAnalyticsEvent("User", "Opened Extension Lander")
  }

  render() {

    return (
      <div style={{height: '100%'}}>
        
        <div className="page-body-c account-body-c">
          <AccountSettings />
        </div>
      </div>
    )
  }
}