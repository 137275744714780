import { memo } from 'react'
import { Line } from 'react-chartjs-2'
import merge from 'lodash/merge'

const getChartProps = ({
  data,
  options = {},
  plugins = [],
  onClick,
}) => {
  const defaultOptions = {
    clip: false,
    interaction: {
      intersect: false,
      mode: 'index',
      axis: 'x',
    },
    layout: {
      padding: {
        top: 20,
        right: 20,
        bottom: 0,
        left: 20,
      },
    },
    onClick: (_el, items) => {
      // Assumes single item
      if (!onClick) return
      const [item] = items
      if (!item) return
      onClick(item.element.$context)
    },

    plugins: {
      legend: {
        display: false,
        position: 'bottom',
        align: 'center',
        maxHeight: 24,
        labels: {
          usePointStyle: true,
          boxWidth: 6,
          boxHeight: 6,
          padding: 10,
        },
      },
      datalabels: {
        display: 'auto',
        padding: 0,
        anchor: 'end',
        align: 'top',
      },
      tooltip: {
        usePointStyle: true,
        displayColors: false,
        boxWidth: 9,
        boxHeight: 9,
        boxPadding: 4,
      },
    },
    scales: {
      x: {
        type: 'category',
        ticks: {
          padding: 1,
        },
      },
      y: {
        display: false,
      },
    },
    elements: {
      line: {
        borderWidth: 2,
        borderColor: 'rgba(44, 110, 142, 1)',
        fill: {
          above: 'rgba(44, 110, 142, 0.1)',
          target: 'origin',
        },
      },
      point: {
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0)',
        radius: 4,
        hoverRadius: 4,
        hoverBorderWidth: 1,
        backgroundColor: 'rgba(44, 110, 142, 1)',
        hoverBorderColor: 'rgba(44, 110, 142, 1)',
      },
    },
  }

  return {
    options: merge(defaultOptions, options),
    data,
    plugins,
  }
}

const LineChart = memo(props => {
  const chartProps = getChartProps(props)

  return (
    <div style={{width: '100%', height: '100%', position: 'relative'}}>
      <Line {...chartProps} />
    </div>
  )
})

export default LineChart
