import { useCallback, useEffect, useMemo, useRef, useState, memo } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import ValuationStore from '../../api/stores/ValuationStore'
import Spinner from '../../components/common/Spinner'
import CommonFunctions from '../../api/constants/CommonFunctions'
import pluralize from 'pluralize'
import Formatters from '../../api/constants/Formatters'
import ConnectivityStore from '../../api/stores/ConnectivityStore'

const componentProps = (data, showValueLabels) => {
  return {
    yearMakeModel: `${data.year} ${data.make} ${data.model}`,
    trim: data.full_trim,
    dealerName: data.dealer_name,
    price: Formatters.formatCurrency(data.price),
    mileage: data.mileage ? `${Formatters.formatThousands(data.mileage)} ${showValueLabels ? pluralize('mile', data.mileage) : ''}` : null,
    daysOnLot: `${Formatters.formatThousands(data.days_on_lot)} ${showValueLabels ? pluralize('day', data.days_on_lot) : ''}`,
    numPriceChanges: showValueLabels ? pluralize('price change', data.num_price_changes, true) : data.num_price_changes,
    photoUrl: data.primary_photo_url ? `${ConnectivityStore.apolloApiRoot}${data.primary_photo_url}` : null,
    distance: data.distance ? `${Formatters.formatThousands(parseInt(data.distance))}` : null,
  }
}

const ListingImage = ({ src, style, className }) => {
  const hasErrorRef = useRef(false)
  const fallbackImage = '/images/missing-photo-placeholder.png'

  const onError = useCallback((e) => {
    e.target.src = hasErrorRef.current ? '' : fallbackImage
    hasErrorRef.current = true
  }, [])

  return (
    <img
      src={src || fallbackImage}
      loading='lazy'
      onError={onError}
      style={{ ...style, objectFit: 'cover', objectPosition: 'center' }}
      className={className}
      alt='Listing'
    />
  )
}

const ListingsList = memo(({ regionalListingsParams, order = 'mileage', getScrollParent, showYMMT = true, showValueLabels = true, showDistance = false, filter }) => {
  const clientID = useMemo(() => {
    return CommonFunctions.cacheKeyFromObject({ ...regionalListingsParams, order })
  }, [regionalListingsParams, order])

  const [
    {
      isLoading,
      data,
      endOfListReached,
    },
    setState,
  ] = useState({
    isLoading: false,
    data: null,
    endOfListReached: false,
  })

  const onDataChanged = useCallback(() => {
    const data = ValuationStore.getRegionalListingsWithClientID(clientID)
    setState(prev => ({
      ...prev,
      data: data.data,
      isLoading: false,
      endOfListReached: data.isLastPage,
    }))
  }, [clientID])

  useEffect(() => {
    const ev = `regional_listings_change_${clientID}`
    ValuationStore.on(ev, onDataChanged)

    setState(prev => ({ ...prev, isLoading: true }))
    ValuationActionCreators.loadRegionalListings({ ...regionalListingsParams, clientID, order })

    return () => {
      ValuationStore.removeListener(ev, onDataChanged)
    }
  }, [regionalListingsParams, order, onDataChanged, clientID])

  const loadMore = useCallback((page) => {
    if (page > 1)
    ValuationActionCreators.loadRegionalListings({ ...regionalListingsParams, clientID, page, order })
  }, [regionalListingsParams, clientID, data, order])

  const filteredData = useMemo(() => {
    return filter && data ? data.filter(filter) : data
  }, [data, filter])

  // FIXME: handle no data, errors

  return (
    <>
      {
        isLoading ?
        <Spinner /> : (
          filteredData &&
          <InfiniteScroll
            pageStart={1}
            loadMore={loadMore}
            getScrollParent={getScrollParent}
            useWindow={false}
            hasMore={!endOfListReached}
            loader={<Spinner key="pagination-spinner" />}
          >
            {
              !filteredData.length ?
              <div>No listings found</div> :
              filteredData.map(item => {
                const { photoUrl, yearMakeModel, trim, dealerName, price, mileage, daysOnLot, numPriceChanges, distance } = componentProps(item, showValueLabels)
                return (
                  <div className="split-row" style={{borderBottom: '1px solid #eee'}} key={item.id}>
                    <ListingImage
                      src={photoUrl}
                      style={{width: '100px', height: '75px', marginRight: '10px'}}
                      className='listings-list-photo'
                    />
                    <div style={{flexShrink: 1, flexGrow: 1}}>
                      <div className="split-row">
                        {
                          showYMMT &&
                          <div style={{flexGrow: 1, flexShrink: 1}}>
                            <div className="list-emphasis">{yearMakeModel}</div>
                            <div className="secondary-text">{trim}</div>
                            <div className="secondary-text">{dealerName}</div>
                          </div>
                        }
                        <div className='listings-list-price'>
                          {price}
                        </div>

                        <div className='listings-list-mileage'>
                          {mileage}
                        </div>

                        {
                          showDistance &&
                          <div className='listings-list-distance'>
                            {distance}
                          </div>
                        }

                        <div className='listings-list-dol'>
                          {daysOnLot}
                        </div>

                        <div className='listings-list-price-changes'>
                          {numPriceChanges}
                        </div>
                      </div>
                      {
                        !showYMMT &&
                        <div className="secondary-text">{dealerName}</div>
                      }
                    </div>
                  </div>
                )
              })
            }
          </InfiniteScroll>
        )
      }
    </>
  )
})

export default ListingsList
