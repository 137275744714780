import { useCallback, useRef } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import VehiclesListContainer from './VehiclesListContainer'

const VehiclesListModal = ({ onClose, title, excludedSortOptions, query, defaultOrder }) => {
  const ref = useRef(null)

  const getScrollParent = useCallback(() => {
    return ref.current
  }, [])

  // FIXME: For pagination, moved scroll to parent, so footer close button is not visible

  return (
    <Modal isOpen innerRef={ref} centered toggle={onClose} backdropClassName='modal-bg' contentClassName='modal-c' style={{ minWidth: 1200 }}>
      <ModalHeader className='modal-header'>
        {title}
      </ModalHeader>
      <ModalBody>
        <VehiclesListContainer
          getScrollParent={getScrollParent}
          excludedSortOptions={excludedSortOptions}
          query={query}
          defaultOrder={defaultOrder}
        />
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={onClose}>Close</Button>
      </ModalFooter>
    </Modal>
  )
}

export default VehiclesListModal
