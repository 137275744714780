import React from 'react'

export default class ProviderLotSenseLogo extends React.PureComponent {
  render() {
    const width = this.props.width || 200
    const height = this.props.height || 104

    return (
      <img src="/images/lot-sense-logo.png" style={{width: width, height: height, ...this.props.style}} />
    )
  }
}
