import { useEffect, useState, useCallback } from 'react'
import { Spinner } from 'reactstrap'
import moment from 'moment'
import AppConstants from '../../api/constants/AppConstants'
import ValuationStore from '../../api/stores/ValuationStore'
import ExternalLinkIcon from '../common/icons/ExternalLinkIcon'
import ValuationProviderCard from './ValuationProviderCard'
import ValuationFunctions from '../../api/constants/ValuationFunctions'

const Vintel = ({ vehicleUUID, hasVIN }) => {
  const [
    {
      isLoading,
      report,
    },
    setState,
  ] = useState({
    isLoading: ValuationStore.vintelIsLoadingForVehicle(vehicleUUID),
    report: ValuationStore.vintelReportWithID(vehicleUUID),
  })

  const onReportChange = useCallback(() => {
    setState(prev => ({
      ...prev,
      report: ValuationStore.vintelReportWithID(vehicleUUID),
    }))
  }, [vehicleUUID])

  const onIsLoadingChange = useCallback(() => {
    setState(prev => ({
      ...prev,
      isLoading: ValuationStore.vintelIsLoadingForVehicle(vehicleUUID),
    }))
  }, [vehicleUUID])

  useEffect(() => {
    ValuationStore.on(`vintel_report_changed_${vehicleUUID}`, onReportChange)
    ValuationStore.on(`vintel_report_is_loading_changed_${vehicleUUID}`, onIsLoadingChange)
    return () => {
      ValuationStore.removeListener(`vintel_report_changed_${vehicleUUID}`, onReportChange)
      ValuationStore.removeListener(`vintel_report_is_loading_changed_${vehicleUUID}`, onIsLoadingChange)
    }
  }, [vehicleUUID, onReportChange, onIsLoadingChange])

  const renderColumn = item => {
    if (!item) return null
    const { key, title, value, iconUrl } = item

    return (
      <div
        key={key}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          border: '1px solid #ddd',
          borderRadius: '10px',
          flexBasis: '22%',
          padding: '10px',
        }}
      >
        <div>
          <img
            style={{ height: 34, width: 'auto', marginBottom: '5px' }}
            src={iconUrl}
            alt='icon'
          />
        </div>
        <div style={{ textAlign: 'center', fontSize: '0.95rem', color: '#666'}}>
          {title}
        </div>
        <div
          style={{
            textAlign: 'center',
            lineHeight: '0.9rem',
            fontWeight: 'bold'
          }}
        >
          {value}
        </div>
      </div>
    )
  }

  const renderBody =  () => {
    if (!hasVIN) return (<div>VIN required for this report</div>)
    if (!report) return null
    if (report.errors) return (<div>{report.errors}</div>)

    return (
      <div>
        <h3>Report from {moment(report.REPORT.dsrTimeStamp).format('MMM Do')}</h3>
        <div
          style={{
            backgroundColor: '#fff',
            display: 'flex',
            flexWrap: 'wrap',
            borderRadius: 6,
            padding: 10,
            marginTop: 10,
            rowGap: '20px',
            columnGap: 20,
            marginLeft: -5,
            marginRight: -5,
          }}
        >
          { ValuationFunctions.vintelDashboardItems(report).map(renderColumn) }
        </div>
        {
          report.publicUrl &&
          <div>
            <div className="full-report-link">
              <a href={report.publicUrl} target="_blank" rel="noopener noreferrer">View Full Report in New Window <ExternalLinkIcon style={{marginTop: '-2px'}} /></a>
            </div>
          </div>
        }
      </div>
    )
  }

  return (
    <ValuationProviderCard title={AppConstants.providerNames.vintel} forceHasData providerKey='vintel'>
      {
        isLoading ?
        <Spinner /> :
        renderBody()
      }
    </ValuationProviderCard>
  )
}

export default Vintel
