import { useMemo } from 'react'
import VehiclesListContainer from './VehiclesListContainer'
import pluralize from 'pluralize'
import Header from './Header'
import Formatters from '../../api/constants/Formatters'
import UserActionCreators from '../../api/actions/UserActionCreators'
import AppConstants from '../../api/constants/AppConstants'

const VehiclesByStatus = ({ title, status, count, defaultOrder, havingAlerts, excludedSortOptions }) => {
  const query = useMemo(() => {
    UserActionCreators.createMetric(AppConstants.metricNames['my_lot_view_listings'], status || title)
    return { state: status, having_alerts: havingAlerts }
  }, [status, havingAlerts])

  const rowOptions = useMemo(() => {
    return {
      showSnoozeAlertsToggle: Boolean(havingAlerts),
    }
  }, [havingAlerts])

  return (
    <div>
      <Header title={title} />
      <VehiclesListContainer
        query={query}
        rowOptions={rowOptions}
        defaultOrder={defaultOrder}
        subtitle={`${count != null ? `${Formatters.formatThousands(count)} ${pluralize('vehicle', count, false)}` : ''}`}
        excludedSortOptions={excludedSortOptions}
      />
    </div>
  )
}

export default VehiclesByStatus
