import { memo } from 'react'
import { Bar } from 'react-chartjs-2'
import merge from 'lodash/merge'

const getChartProps = ({
  data,
  options = {},
  plugins = [],
  onClick,
}) => {
  const defaultOptions = {
    layout: {
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    onClick: (_e, items) => {
      // Assumes single item
      if (!onClick) return
      const [item] = items
      if (!item) return
      onClick(item.element.$context)
    },
    interaction: {
      intersect: false,
      mode: 'index',
      axis: 'x',
    },
    plugins: {
      tooltip: {
        itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
        usePointStyle: true,
        displayColors: false,
        boxWidth: 9,
        boxHeight: 9,
        boxPadding: 4,
      },
    },
    scales: {
      x: {
        type: 'category',
        stacked: false,
        ticks: {
          autoSkip: false,
          font: {
            size: 11,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        stacked: false,
      },
    },
    elements: {
      bar: {
        backgroundColor: 'rgba(44, 110, 142, 1)',
      },
    },
  }

  return {
    options: merge(defaultOptions, options),
    data,
    plugins,
  }
}

const BarChart = memo(props => {
  const chartProps = getChartProps(props)

  return (
    <div style={{width: '100%', height: '100%', position: 'relative'}}>
      <Bar {...chartProps} />
    </div>
  )
})

export default BarChart
