import moment from 'moment'

export default {

  colorForDaysOnLot: function(dol, defaultColor = 'inherit') {
    if (dol > 30) {
      return 'red'
    } else {
      return defaultColor
    }
  },

  myLotVehicleURL: function(valuation) {
    return `/lot-sense/vehicles/show/${valuation.uuid}`
  },
  
  snoozeOptions: [
    { label: 'For 4 days', valueFn: () => moment().add(4, 'days') },
    { label: 'Forever', valueFn: () => 'infinity' },
  ]

}