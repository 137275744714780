import { useCallback, useMemo, useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import RcSlider from 'rc-slider'
import platformFunctions from '../../platformFunctions'
import CurrencyInputField from '../common/CurrencyInputField'

const Slider = ({ value: defaultValue, labelFormatter, marks, step, onChange, range, min, max }) => {
  const [value, setValue] = useState(defaultValue)

  return (
    <div>
      <div className='secondary-text'>{labelFormatter(value)}</div>
      <div style={{ marginRight: 10, marginLeft: 10, marginBottom: 16 }}>
        <RcSlider
          step={step}
          allowCross={false}
          min={min}
          max={max}
          marks={marks}
          onAfterChange={onChange} // Prop deprecated, but we need the feature, supported in later rc-slider version
          range={range}
          value={value}
          onChange={setValue}
          {...platformFunctions.sliderStyleProps()}
        />
      </div>
    </div>
  )
}

const AlertsOptionsModal = ({ title, values: initialValues, onClose, onSave }) => {
  const [values, setValues] = useState(initialValues)

  const onSubmit = useCallback(() => {
    onSave(values)
  }, [onSave, values])

  const isValid = useMemo(() => {
    // TODO: validate?
    return true
  }, [values])

  const percentOfMarketSliderValue = useMemo(() => {
    return [values.alertsPercentOfMarketLowThreshold, values.alertsPercentOfMarketHighThreshold]
  }, [values.alertsPercentOfMarketLowThreshold, values.alertsPercentOfMarketHighThreshold])

  return (
    <Modal isOpen={true} centered={true} backdropClassName='modal-bg' contentClassName='modal-c'  style={{minWidth: '600px'}}>
      <form onSubmit={(e) => {
        e.preventDefault()
        if (isValid) {
          onSubmit()
        } }}>
        <ModalHeader className='modal-header'>
          {title}
        </ModalHeader>
        <ModalBody>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 40 }}>
            <div>
              <div>Days on lot threshold</div>
              <p className='secondary-text'>You will be alerted if a vehicle's days on lot number exceeds this value.</p>
              <Slider
                min={5}
                max={100}
                marks={{
                  5: '5',
                  10: '10',
                  15: '15',
                  20: '20',
                  25: '25',
                  30: '30',
                  35: '35',
                  40: '40',
                  45: '45',
                  50: '50',
                  55: '55',
                  60: '60',
                  70: '70',
                  80: '80',
                  90: '90',
                  100: '100',
                }}
                value={values.alertsDaysOnLotThreshold}
                step={1}
                onChange={v => setValues((prev) => ({ ...prev, alertsDaysOnLotThreshold: v}))}
                labelFormatter={v => `Above ${v} days`}
              />
            </div>

            <div>
              <div>Percent of market thresholds</div>
              <p className='secondary-text'>You will be alerted if a vehicle's retail value falls outside of this range.</p>
              <Slider
                min={60}
                max={140}
                marks={{
                  60: '60%',
                  70: '70%',
                  80: '80%',
                  90: '90%',
                  100: '100%',
                  110: '110%',
                  120: '120%',
                  130: '130%',
                  140: '140%',
                }}
                value={percentOfMarketSliderValue}
                step={1}
                range
                onChange={v => setValues((prev) => ({ ...prev, alertsPercentOfMarketLowThreshold: v[0], alertsPercentOfMarketHighThreshold: v[1] }))}
                labelFormatter={([min, max]) => `Below ${min}% or above ${max}%`}
              />
            </div>

            <div>
              <div>Retail profit threshold</div>
              <p className='secondary-text'>You will be alerted if a vehicle's potential retail profit falls below this value. You have the option to override this value for each vehicle.</p>

              <p className='secondary-text'>For example, if you wish to be notified when profit potential falls below $2,000, enter that value below.</p>
              <CurrencyInputField
                unmask
                value={values.alertsRetailProfitThreshold}
                onChange={v => setValues(prev => ({ ...prev, alertsRetailProfitThreshold: v.length ? parseInt(v) : null}))}
                style={{ margin: 0, width: 240 }}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={onClose}>Cancel</Button>
          <Button type='submit' color='primary' onClick={onSubmit} disabled={!isValid}>Save Options</Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default AlertsOptionsModal
