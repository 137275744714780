import { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import ValuationStore from '../../api/stores/ValuationStore'
import CommonFunctions from '../../api/constants/CommonFunctions'
import Spinner from '../common/Spinner'
import useMyLotSettings from '../../api/hooks/useMyLotSettings'
import pluralize from 'pluralize'
import TrendUpIcon from '../common/icons/TrendUpIcon'
import TrendDownIcon from '../common/icons/TrendDownIcon'
import ListingsListWithHeader from './ListingsListWithHeader'
import pick from 'lodash/pick'
import Formatters from '../../api/constants/Formatters'

const scorecardMetricTypes = [
  'inventory',
  'days_on_lot',
  'market_days_supply',
  'sold_per_day',
  'price_change_portion',
  'retail_trend',
  'wholesale_inventory',
]

const Scorecard = ({ valuation, className }) => {
  const {
    year,
    make,
    model,
    series: trim,
  } = valuation

  const [{ regionSettings: { zip } }] = useMyLotSettings()

  const clientID = useMemo(() => {
    return CommonFunctions.cacheKeyFromObject({ year, make, model, trim, zip })
  }, [zip, year, make, model, trim])

  const [
    {
      isLoading,
      data: scorecard,
    },
    setState,
  ] = useState({
    isLoading: ValuationStore.getRegionalModelScorecardIsLoadingWithClientID(clientID),
    data: ValuationStore.getRegionalModelScorecardWithClientID(clientID),
  })

  const regionalListingsParams = useMemo(() => {
    return scorecard ? pick(scorecard, ['year', 'make', 'model', 'trim', 'zip', 'radius']) : null
  }, [scorecard])

  const onDataChanged = useCallback(() => {
    setState(prev => ({
      ...prev,
      data: ValuationStore.getRegionalModelScorecardWithClientID(clientID),
    }))
  }, [clientID])

  const onIsLoadingChanged = useCallback(() => {
    setState(prev => ({ ...prev, isLoading: ValuationStore.getRegionalModelScorecardIsLoadingWithClientID(clientID) }))
  }, [clientID])

  useLayoutEffect(() => {
    const loadingEv = `regional_model_scorecard_is_loading_change_${clientID}`
    const dataEv = `regional_model_scorecard_change_${clientID}`

    ValuationStore.on(dataEv, onDataChanged)
    ValuationStore.on(loadingEv, onIsLoadingChanged)

    return () => {
      ValuationStore.removeListener(dataEv, onDataChanged)
      ValuationStore.removeListener(loadingEv, onIsLoadingChanged)
    }
  }, [onDataChanged, onIsLoadingChanged, clientID])

  useLayoutEffect(() => {
    if (!scorecard) {
      ValuationActionCreators.loadRegionalModelScorecard({ year, make, model, trim, zip, clientID })
    }
  }, [zip, year, make, model, trim, clientID, scorecard])

  if (isLoading) return <Spinner />

  return (
    <div className={className}>
      {
        scorecard &&
        <div>
          Showing inventory within {Formatters.formatThousands(scorecard.radius)} {pluralize('mile', scorecard.radius, false)} from zip {zip}
        </div>
      }
      <div className='split-row split-row-left' style={{columnGap: '10px', marginTop: '10px', alignItems: 'stretch'}}>
        <div className='my-lot-regional-metrics'>
          {
            // FIXME: Handle no scorecard data (i.e. no make/model stats in query)
            !scorecard ?
            <div>No data available</div> :
            scorecardMetricTypes.map(key => {
              const data = scorecard[key]
              // FIXME Handle missing data
              if (data == null) return null
              let levelBound
              let levelIndicator
              let trendText
              let trendIcon
              let title
              let currentValueText
              let levelName

              // FIXME: Handle insufficient data
              const insufficientLevelDataText = 'Low data'

              switch (key) {
                case 'inventory':
                  title = 'Local Retail Inventory'
                  currentValueText = `${data.current} within ${pluralize('mile', scorecard.radius, true)}`
                  levelName = 'supply'
                  break
                case 'days_on_lot':
                  title = 'Days on Lot'
                  currentValueText = data.current == null ? 'N/A' : [data.current.toFixed(1), pluralize('day', data.current, false)].join(' ')
                  levelName = data.level == null ? insufficientLevelDataText : 'turn'
                  break
                case 'sold_per_day':
                  title = 'Daily Sales Rate'
                  currentValueText = data.current == null ? 'N/A' : [data.current.toFixed(2), 'per day'].join(' ')
                  levelName = data.level == null ? insufficientLevelDataText : 'rate'
                  break
                case 'price_change_portion':
                  title = 'Price Change Percent'
                  currentValueText = data.current == null ? 'N/A' : [(data.current * 100).toFixed(2), '%'].join('')
                  levelName = data.level == null ? insufficientLevelDataText : 'percent'
                  break
                case 'market_days_supply':
                  title = 'Market Days Supply'
                  currentValueText = data.current == null ? 'N/A' : [data.current.toFixed(1), pluralize('day', data.current, false)].join(' ')
                  levelName = data.level == null ? insufficientLevelDataText : 'number'
                  break
                case 'retail_trend':
                  title = 'Retail Trend'
                  currentValueText = '',
                  levelName = ''
                  break
                case 'wholesale_inventory':
                  title = 'Local Wholesale Inventory'
                  currentValueText = [data.current, 'listed recently'].join(' ')
                  levelName = data.level == null ? insufficientLevelDataText : 'supply'
                  break
                default:
                  throw new Error(`Unknown scorecard metric type: ${key}`)
              }

              switch (data?.level) {
                case -1:
                  levelBound = 'Low'
                  levelIndicator = '▼'
                  break
                case 0:
                  levelBound = 'Normal'
                  levelIndicator = '-'
                  break
                case 1:
                  levelBound = 'High'
                  levelIndicator = '▲'
                  break
              }

              switch (data?.trend) {
                case -1:
                  trendText = 'Trending down'
                  trendIcon = <TrendDownIcon />
                  break
                case 0:
                  trendText = 'Trending flat'
                  trendIcon = '-'
                  break
                case 1:
                  trendText = 'Trending up'
                  trendIcon = <TrendUpIcon />
                  break
              }

              return (
                <section key={key}>
                  <div className='split-row'>
                    <div className='section-label'>{title}</div>
                  </div>
                  <h2>{currentValueText}</h2>
                  <div className='split-row'>
                    <div><span className='indicator'>{levelIndicator}</span> {levelBound} {levelName}</div>
                    <div>{trendText} <span className='indicator'>{trendIcon}</span></div>
                  </div>
                </section>
              )
            })
          }
        </div>

        <div className='my-lot-regional-comps'>
          {
            regionalListingsParams &&
            <ListingsListWithHeader regionalListingsParams={regionalListingsParams} showYMMT={false} />
          }
        </div>
      </div>
    </div>
    )
  }

export default Scorecard
