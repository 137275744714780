import React from 'react'
import ProviderBlackBookLogo from './ProviderBlackBookLogo'
import ProviderNadaLogo from './ProviderNadaLogo'
import ProviderRealRetailLogo from './ProviderRealRetailLogo'
import ProviderLiveLocalMarketLogo from './ProviderLiveLocalMarket'
import ProviderKBBLogo from './ProviderKBBLogo'
import ProviderPipelineLogo from './ProviderPipelineLogo'
import ProviderManheimLogo from './ProviderManheimLogo'
import ProviderGalvesLogo from './ProviderGalvesLogo'
import ProviderMarketTrackerLogo from './ProviderMarketTrackerLogo'
import ProviderAuctionsPlusLogo from './ProviderAuctionsPlusLogo'
import ProviderLotSenseLogo from './ProviderLotSenseLogo'
import ProviderCarblyPlusBundleLogo from './ProviderCarblyPlusBundleLogo'

export default class ProviderLogo extends React.PureComponent {
  render() {
    switch (this.props.providerKey) {
      case 'black_book':
        return <ProviderBlackBookLogo {...this.props} />
      case 'manheim':
        return <ProviderManheimLogo {...this.props} />
      case 'pmr':
        return <ProviderPipelineLogo {...this.props} />
      case 'pin':
        return <ProviderRealRetailLogo {...this.props} />
      case 'universe':
        return <ProviderLiveLocalMarketLogo {...this.props} />
      case 'nada':
        return <ProviderNadaLogo {...this.props} />
      case 'kbb':
        return <ProviderKBBLogo {...this.props} />
      case 'galves':
        return <ProviderGalvesLogo {...this.props} />
      case 'market_tracker':
        return <ProviderMarketTrackerLogo {...this.props} />
      case 'auctions_plus':
        return <ProviderAuctionsPlusLogo {...this.props} />
      case 'lot_sense':
        return <ProviderLotSenseLogo {...this.props} />
      case 'carbly_plus_bundle':
        return <ProviderCarblyPlusBundleLogo {...this.props} />
      default:
        // FIXME: hack because server isn't setup to return provider key for regional report
        if (!this.props.providerKey) {
          return <ProviderMarketTrackerLogo {...this.props} />
        }
        return null
    }
  }
}
