import { useState, useCallback } from 'react'
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap'
import MyLotFunctions from '../../api/constants/MyLotFunctions'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import UserActionCreators from '../../api/actions/UserActionCreators'
import AppConstants from '../../api/constants/AppConstants'

const SnoozeAlertsToggle = ({ clientID, lotVehicle }) => {
  const { uuid, is_alert_snoozed: isSnoozed } = lotVehicle
  const hasAlerts = lotVehicle.alerts.length > 0
  const [snoozePopoverIsShown, setSnoozePopoverIsShown] = useState(false)

  const onSnoozeClick = useCallback((e, valueFn, label) => {
    e.preventDefault()
    const params = {
      alert_snooze_until: valueFn(),
      uuid,
    }
    ValuationActionCreators.updateLotVehicle(params, clientID)
    UserActionCreators.createMetric(AppConstants.metricNames['my_lot_snoozed'], null, { alert_snooze_until: label, uuid: uuid })
    setSnoozePopoverIsShown(false)

  }, [uuid, clientID])

  const onUnsnoozeAlertsClick = useCallback(() => {
    const params = {
      alert_snooze_until: null,
      uuid,
    }
    ValuationActionCreators.updateLotVehicle(params, clientID)
  }, [uuid, clientID])

  return (
    <>
      <div>
        {
          hasAlerts && !isSnoozed &&
          <button
            className='text-button'
            style={{color: '#888'}}
            id={`alerts-snooze-${uuid}`}
            onClick={(e) => e.preventDefault()}
          >
            Snooze alerts
          </button>
        }
        {
          isSnoozed &&
          <button
            className='text-button'
            style={{color: '#888'}}
            onClick={onUnsnoozeAlertsClick}
          >
            Unsnooze alerts
          </button>
        }
      </div>

      {
        hasAlerts && !isSnoozed &&
        <Popover
          placement='bottom-start'
          fade={false}
          isOpen={snoozePopoverIsShown}
          trigger='legacy'
          target={`alerts-snooze-${uuid}`}
          toggle={() => setSnoozePopoverIsShown(!snoozePopoverIsShown)}
        >
          <PopoverHeader>Snooze Alerts</PopoverHeader>
          <PopoverBody className='popover-sm'>
            <div style={{marginBottom: '10px'}}>Don't show alerts for this vehicle:</div>
            {
              MyLotFunctions.snoozeOptions.map((option, i) => (
                <a
                  key={i}
                  className='popover-item'
                  style={{backgroundColor: '#eee', marginBottom: '5px', borderRadius: '5px'}}
                  href={`#snooze-${i}`}
                  onClick={(e) => onSnoozeClick(e, option.valueFn, option.label)}
                >
                  {option.label}
                </a>
              ))
            }
          </PopoverBody>
        </Popover>
      }
    </>
  )
}

export default SnoozeAlertsToggle
