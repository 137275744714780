import { useCallback, useMemo, useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Spinner from '../../components/common/Spinner'
import BarChart from '../../components/myLot/BarChart'
import LineChart from '../../components/myLot/LineChart'
import useMyLotReport from '../../api/hooks/useMyLotReport'
import useMyLotCompetitorsReport from './util/useMyLotCompetitorsReport'
import ChartSection from './ChartSection'
import Header from './Header'
import Formatters from '../../api/constants/Formatters'
import { regionalReportOptions } from '../../components/retail/constants'
import DealersMap from '../../components/myLot/DealersMap'
import useMyLotSettings from '../../api/hooks/useMyLotSettings'
import pluralize from 'pluralize'
import SessionStore from '../../api/stores/SessionStore'
import UserActionCreators from '../../api/actions/UserActionCreators'
import AppConstants from '../../api/constants/AppConstants'

const rankAttributes = {
  num_sold: {
    name: 'Vehicles Sold',
    formatRank: Formatters.formatThousands,
    formatValue: Formatters.formatThousands,
    formatListValue: v => `${Formatters.formatThousands(v)} ${pluralize('vehicle', v)} sold`,
  },
  inventory: {
    name: 'Inventory Level',
    formatRank: Formatters.formatThousands,
    formatValue: v => `${Formatters.formatThousands(v)} ${pluralize('vehicle', v)}`,
    formatListValue: v => `${Formatters.formatThousands(v)} ${pluralize('vehicle', v)}`,
  },
  inventory_value: {
    name: 'Inventory Value',
    formatRank: Formatters.formatThousands,
    formatValue: Formatters.formatCurrency,
    formatListValue: Formatters.formatCurrency,
  },
  avg_days_on_lot: {
    name: 'Days on Lot',
    formatRank: Formatters.formatThousands,
    formatValue: v => v === null ? 'N/A' : pluralize('day', v.toFixed(1), true),
    formatListValue: v => v === null ? 'N/A' : pluralize('day', v.toFixed(1), true),
  },
}

const Competitors = () => {
  const defaultDealerType = SessionStore.myLotCompetitorsScope ? SessionStore.myLotCompetitorsScope : 'all'
  const [{ regionSettings: { zip, radius } }] = useMyLotSettings()
  const [dealerType, setDealerType] = useState(defaultDealerType)
  const [selectedCompetitorsRankList, setSelectedCompetitorsRankList] = useState(Object.keys(rankAttributes)[0])
  const competitorsListRef = useRef()
  const competitorsListItemScrollRef = useRef()

  const onSelectDealerTypeClick = useCallback((value, e) => {
    e.preventDefault()
    SessionStore.setMyLotCompetitorsScope(value)
    setDealerType(value)
  }, [])

  const onSelectRankAttributeClick = useCallback((value, e) => {
    e.preventDefault()
    setSelectedCompetitorsRankList(value)
  }, [])

  const selectedRadiusText = useMemo(() => {
    return regionalReportOptions.radius.find(item => item.value === radius).label
  }, [radius])

  const competitorsParams = useMemo(() => {
    return {
      radius,
      dealerType,
      zip,
    }
  }, [radius, zip, dealerType])

  const {
    data: lotReport,
    isLoading: isLoadingLotReport,
  } = useMyLotReport()

  const {
    data: competitorsReport,
    isLoading: isLoadingCompetitorsReport,
  } = useMyLotCompetitorsReport(competitorsParams)

  const combinedChartData = useMemo(() => {
    if (!lotReport || !competitorsReport) return null
    return {
      ...lotReport,
      ...competitorsReport,
    }
  }, [lotReport, competitorsReport])

  const { rankings, dealers }  = competitorsReport || {}
  const isLoading = isLoadingLotReport || isLoadingCompetitorsReport

  const rankedCompetitorsList = useMemo(() => {
    if (!dealers) return null
    return [...dealers].sort((a, b) => a[selectedCompetitorsRankList].rank - b[selectedCompetitorsRankList].rank)
  }, [dealers, selectedCompetitorsRankList])

  useEffect(() => {
    if (competitorsListItemScrollRef.current) {
      const height = competitorsListRef.current.clientHeight;
      competitorsListRef.current.scrollTo({
        top: competitorsListItemScrollRef.current.offsetTop - competitorsListRef.current.offsetTop - (height ? height / 3 : 0), behavior: "smooth",
      })
    }
  }, [rankedCompetitorsList, competitorsListItemScrollRef.current, competitorsListRef.current])

  UserActionCreators.createMetric(AppConstants.metricNames['my_lot_view_competitors'])

  return (
    <div>
      <Header title='Competitors'>
        <div>
          Includes all dealers within {selectedRadiusText} of zipcode {zip}. <Link to="/lot-sense/settings">Edit region</Link>
        </div>
      </Header>
      {
        competitorsReport?.dealers?.length > 0 &&
        <DealersMap dealers={competitorsReport.dealers} />
      }
      <div>

        {
          isLoading ?
          <Spinner /> :
          <div>
            {
              !isLoadingCompetitorsReport &&
              <div style={{borderBottom: '2px solid #111', marginBottom: '20px', marginTop: '20px'}}>
                <div className='my-lot-competitors-scope-c'>
                  {
                    regionalReportOptions.dealerType.map((item, index) => (
                      <a href="#filter"
                        className={dealerType === item.value ? 'selected' : ''}
                        key={index}
                        onClick={onSelectDealerTypeClick.bind(null, item.value)}
                      >
                        {item.label}
                      </a>
                    ))
                  }
                </div>
                <h2>Rankings</h2>
                <div style={{marginBottom: '5px'}}>Your rankings vs {Formatters.formatThousands(rankings.dealers_count)} dealers in your region</div>
              </div>
            }

            <div>
              {
                isLoadingCompetitorsReport ?
                <Spinner /> :
                <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 20, marginTop: -6, marginLeft: -6, marginRight: -6, justifyContent: 'space-between' }}>
                  <div style={{ width: '54%', padding: 6 }}>

                    <div className='my-lot-competitors-ranks-c'>
                      {
                        Object.keys(rankAttributes).map((key, index) => {
                          const { name, formatRank, formatValue } = rankAttributes[key]
                          return (
                            <div key={index}>
                              <div className='my-lot-competitors-rank'>
                                <div>
                                  <div className='my-lot-competitors-rank-label'>Rank</div>
                                  <div className='my-lot-competitors-rank-value' style={{fontSize: rankings[key].rank > 1000 ? '2.45rem' : '3rem'}}>{rankings[key].rank == null ? 'N/A' : formatRank(rankings[key].rank)}</div>
                                </div>
                              </div>
                              <div className='my-lot-competitors-rank-type-label'>{name}</div>
                              <div className='my-lot-competitors-rank-type-value'>{rankings[key].rank == null ? 'N/A' : formatValue(rankings[key].value)}</div>
                            </div>
                          )
                        })
                      }
                    </div>

                    <ChartSection
                      style={{ width: '100%', padding: 6 }}
                      height={230}
                      title='Rankings over time'
                      subtitle='Your rankings vs. competitors in your region for each category'
                      Chart={LineChart}
                      data={competitorsReport}
                      dataKey='rankings_trend'
                    />

                  </div>

                  <div style={{width: '42%', marginLeft: '20px'}}>
                    <div className='my-lot-competitors-scope-c'>
                      {
                        Object.keys(rankAttributes).map((key, index) => {
                          const { name } = rankAttributes[key]
                          return (
                            <a href="#filter"
                              className={selectedCompetitorsRankList === key ? 'selected' : ''}
                              key={index}
                              onClick={onSelectRankAttributeClick.bind(null, key)}
                            >
                              {name}
                            </a>
                          )
                        })
                      }
                    </div>

                    <div className='my-lot-competitors-list' ref={competitorsListRef}>
                      {
                        rankedCompetitorsList.map(dealer => {
                          const rank = dealer[selectedCompetitorsRankList].rank
                          const value = dealer[selectedCompetitorsRankList].value
                          return (
                            <div
                              key={rank}
                              ref={dealer.is_rooftop ? competitorsListItemScrollRef : null}
                              className={`my-lot-competitors-list-item ${dealer.is_rooftop ? 'my-lot-competitors-list-item-selected' : ''}`}
                            >
                              <div className='rank' style={{fontSize: rank > 999 ? '0.95rem' : '1.3rem'}}>{rank}</div>
                              <div className='dealer-details'>
                                <div className='dealer-name'>{dealer.dealer_name}</div>
                                <div className='dealer-value'>{rankAttributes[selectedCompetitorsRankList].formatListValue(value)}</div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              }
            </div>

            <div style={{borderBottom: '2px solid #111', marginBottom: '10px', marginTop: '50px'}}>
              <h2>Regional Analysis</h2>
            </div>

            <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 30, marginLeft: -6, marginRight: -6 }}>
              <ChartSection
                style={{ width: '50%', padding: 6 }}
                height={160}
                title='Inventory Levels by Type'
                subtitle='Regional inventory levels by body style'
                Chart={BarChart}
                data={combinedChartData}
                dataKey='region.inventory_levels_by_body_style'
              />
              <ChartSection
                style={{ width: '50%', padding: 6 }}
                height={160}
                title='Inventory Levels by Make'
                subtitle='Regional inventory levels by make'
                Chart={BarChart}
                data={combinedChartData}
                dataKey='region.inventory_levels_by_make'
              />
              <ChartSection
                style={{ width: '50%', padding: 6 }}
                height={160}
                title='Average Days on Lot'
                subtitle='Regional average days on lot trend over time'
                Chart={LineChart}
                data={combinedChartData}
                dataKey='region.avg_days_on_lot_trend'
              />
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default Competitors
