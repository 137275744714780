import { memo, useMemo } from 'react'
import pluralize from 'pluralize'
import { Link } from 'react-router-dom'
import Spinner from '../../components/common/Spinner'
import LineChart from '../../components/myLot/LineChart'
import BarChart from '../../components/myLot/BarChart'
import componentProps from './util/componentProps'
import DownArrowIcon from '../../components/common/icons/DownArrowIcon'
import UpArrowIcon from '../../components/common/icons/UpArrowIcon'
import VehiclesListContainer from './VehiclesListContainer'
import useMyLotVehiclesDetails from '../../api/hooks/useMyLotVehiclesDetails'
import useMyLotReport from '../../api/hooks/useMyLotReport'
import ChartSection from './ChartSection'
import Header from './Header'
import useMyLotSettings from '../../api/hooks/useMyLotSettings'
import AlertVehiclePreviewList from './AlertVehiclePreviewList'
import OnboardingPrompts from './OnboardingPrompts'
import AppConstants from '../../api/constants/AppConstants'
import UserActionCreators from '../../api/actions/UserActionCreators'

const chartSpacing = 6
const alertVehiclePreviewLimit = 5

const vehiclesListQuery = { is_in_inventory: true }

const SnapshotSection = memo(({ data, dataKey, title }) => {
  const props = componentProps(data, dataKey)
  const { formattedCurrent, direction } = props

  return (
    <div>
      <div className='split-row' style={{alignItems: 'flex-start'}}>
        <div className='snapshot-value'>{formattedCurrent}</div>
        {
          (function() {
            if (direction === 'up') {
              return <UpArrowIcon color='#2b6d8d' width={13} height={16} />
            } else if (direction === 'down') {
              return <DownArrowIcon color='#2b6d8d' width={13} height={16} />
            }
          })()
        }
      </div>
      <div className='snapshot-label'>{title}</div>
    </div>
  )
})

const Dashboard = () => {
  const [
    {
      regionSettings: {
        zip,
        radius,
      },
      lotInfo: {
        lotName,
      },
      urlReviewStateMessage,
      lotInventoryStatusMessage,
    },
  ] = useMyLotSettings()

  const {
    data: report,
    isLoading: isLoadingReport,
  } = useMyLotReport()

  // FIXME: Use of rankings report is currently commented out below.
  // IF we end up using it here on dashboard, where vehicles can be edited,
  // we need to reload the report on vehicle change event (e.g. see useMyLotReport, handled in parent Page)

  // const competitorsParams = useMemo(() => ({
  //   zip,
  //   radius,
  //   dealerType: 'all',
  // }), [zip, radius])

  // const {
  //   data: rankingsReport,
  //   isLoading: isLoadingRankingsReport,
  // } = useMyLotCompetitorsReport(competitorsParams)

  // const { rankings }  = rankingsReport || {}

  const { getCountForState } = useMyLotVehiclesDetails()
  const currentInventoryCount = getCountForState('in_inventory')
  const alertVehicleCount = getCountForState('having_alerts')

  UserActionCreators.createMetric(AppConstants.metricNames['my_lot_view_home'])

  return (
    <div>
      <Header title={lotName || AppConstants.providerNames['my_lot']} showDealerSiteInfo>
        {
          report &&
          <div className='my-lot-snapshot-c'>
            <SnapshotSection
              title='Vehicles on lot'
              data={report}
              dataKey='lot_analysis.snapshots.num_on_lot'
            />
            <SnapshotSection
              title='Lot retail value'
              data={report}
              dataKey='lot_analysis.snapshots.retail_value'

            />
            <SnapshotSection
              data={report}
              title='Average days on lot'
              dataKey='lot_analysis.snapshots.avg_days_on_lot'
            />
            <SnapshotSection
              title='Vehicles sold'
              data={report}
              dataKey='lot_analysis.snapshots.num_sold'
            />
            <Link to='/lot-sense/analysis' className='button'>View Lot Analysis</Link>
          </div>
        }
      </Header>

      <div>
        {
          urlReviewStateMessage &&
          <div className='secondary-text'>{urlReviewStateMessage}</div>
        }

        {
          lotInventoryStatusMessage &&
          <div className='secondary-text'>{lotInventoryStatusMessage}</div>
        }

        <OnboardingPrompts />

        {
          // FIXME
          !report && isLoadingReport ?
          <Spinner /> :
            <div>
              <div>
                {/* {
                  rankings &&
                  <div>
                    <div style={{ fontWeight: 'bold', color: 'rgb(85, 85, 85)' }}>Rankings vs {Formatters.formatThousands(rankings.dealers_count)} dealers in your region</div>
                    <div style={{ marginBottom: 20 }}>
                      <div>
                        Vehicles sold: {Formatters.formatThousands(rankings.num_sold.value)}, rank {Formatters.formatThousands(rankings.num_sold.rank)}
                      </div>
                    </div>
                  </div>
                } */}
              </div>
              <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '20px', marginTop: -chartSpacing, marginLeft: -chartSpacing, marginRight: -chartSpacing }}>
                  <ChartSection
                    style={{ width: '25%', padding: chartSpacing }}
                    height={110}
                    title='Days on Lot'
                    Chart={LineChart}
                    data={report}
                    dataKey='lot_analysis.trends.avg_days_on_lot'
                  />
                  <ChartSection
                    style={{ width: '25%', padding: chartSpacing }}
                    height={110}
                    title='Inventory Levels'
                    Chart={LineChart}
                    data={report}
                    dataKey='lot_analysis.trends.num_on_lot'
                  />
                  <ChartSection
                    style={{ width: '25%', padding: chartSpacing }}
                    height={110}
                    title='Vehicles Sold'
                    Chart={LineChart}
                    data={report}
                    dataKey='lot_analysis.trends.num_sold'
                  />
                  <ChartSection
                    style={{ width: '25%', padding: chartSpacing }}
                    height={110}
                    title='Current Inventory by Days on Lot'
                    Chart={BarChart}
                    data={report}
                    dataKey='lot_analysis.groups.days_on_lot.num_on_lot'
                  />
                </div>
              </div>
            </div>
        }

        <div style={{ display: 'flex', marginRight: -10, marginLeft: -10, marginBottom: '10px', borderBottom: '1px solid #333', marginTop: '40px'}}>
          <div style={{ padding: '0 10px' }}>
            <h2 style={{ margin: 0 }} className='card-title'>Vehicles Needing Attention</h2>
          </div>

          {
            alertVehicleCount > alertVehiclePreviewLimit &&
            <div style={{ display: 'flex', padding: '0 10px', marginLeft: 'auto', alignItems: 'center' }}>
              <Link to='/lot-sense/alerts'>View all</Link>
            </div>
          }
        </div>

        <AlertVehiclePreviewList limit={alertVehiclePreviewLimit} />

        <VehiclesListContainer
          title='Current Inventory'
          subtitle={`${currentInventoryCount != null ? currentInventoryCount + ' ' + pluralize('vehicle', currentInventoryCount, false) : ''}`}
          query={vehiclesListQuery}
          excludedSortOptions={['sold_at', 'last_alerted_at']}
          headerStyle={{ borderBottom: '1px solid #333', marginBottom: '10px' }}
        />
      </div>
    </div>
  )
}

export default Dashboard
