import React from 'react'
import CalculatorBug from "../../components/common/CalculatorBug"
import AccountBug from "../../components/user/AccountBug"
import AppConstants from "../../api/constants/AppConstants"
import SessionStore from "../../api/stores/SessionStore"
import PlusIcon from "../../components/common/icons/PlusIcon"
import { Button } from "reactstrap"
import UserActionCreators from "../../api/actions/UserActionCreators"
import platformFunctions from '../../platformFunctions'
import Formatters from '../../api/constants/Formatters'
import OfferingUpsellModal from '../../components/common/OfferingUpsellModal'

export default class Upsell extends React.Component {
  state = {
    costForSelected: null,
    accountDetails: null,
    pricingForOfferingsErrors: null,
    isShowingUpsellModal: false
  }
 

  componentDidMount() {
    SessionStore.on("account_details_received", this.accountDetailsReceived)
    SessionStore.on("pricing_for_offerings_received", this.costForSelectedOfferingsReceived)
    SessionStore.on("account_reactivation_received", this.accountReactivationReceived)


    platformFunctions.logAnalyticsEvent("User", `Opened ${AppConstants.providerNames.my_lot} Upsell Page`)
    UserActionCreators.createMetric(AppConstants.metricNames['my_lot_upsell'], this.props.metricsValue)

    UserActionCreators.loadAccountDetails()
  }

  componentWillUnmount() {
    SessionStore.removeListener("account_details_received", this.accountDetailsReceived)
    SessionStore.removeListener("pricing_for_offerings_received", this.costForSelectedOfferingsReceived)
    SessionStore.removeListener("account_reactivation_received", this.accountReactivationReceived)
  }

  accountDetailsReceived = () => {
    const accountDetails = SessionStore.accountDetails
    this.setState({
      accountDetails: accountDetails
    })

    const isDivergent = accountDetails?.divergent_offerings === true
    if (!isDivergent && !SessionStore.isCanceled()) {
      UserActionCreators.loadPricingForOfferings([AppConstants.skuMappings.my_lot])
    }
  }

  costForSelectedOfferingsReceived = () => {
    const pricingForOfferings = SessionStore.pricingForOfferings
    const pricingForOfferingsErrors = SessionStore.pricingForOfferingsErrors

    this.setState({
      costForSelected: pricingForOfferings,
      pricingForOfferingsErrors: pricingForOfferingsErrors
    })
  }

  accountReactivationReceived = () => {
    this.setState({
      costForSelected: null,
      accountDetails: null,
    })
    UserActionCreators.loadAccountDetails()
  }

  render () {
    const { costForSelected, accountDetails, isShowingUpsellModal } = this.state
    const priceString = costForSelected ? ` for ${Formatters.formatCurrencyCents(costForSelected?.incremental_price)} per month` : ''
    const isDivergent = accountDetails?.divergent_offerings === true
    return (
      <div className="page-body-c body-content" style={{minHeight: '100%', marginLeft: '100px'}}>
  
        <div className="content-header">
          <h1>{AppConstants.providerNames['my_lot']}</h1>
  
          <div className="global-tools">
            <AccountBug />
            <CalculatorBug />
          </div>
        </div>
  
        <div style={{marginTop: '20px'}}>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
  
          <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
  
        {
          SessionStore.isAccountOwner() &&
          <Button style={{display: 'flex', alignItems: 'center'}} className="highlight-button"
            onClick={() => {
              this.setState({ isShowingUpsellModal: true })
            }}>
              <div>
                <PlusIcon style={{marginRight: '5px'}} /> Add {AppConstants.providerNames['my_lot']} to your Account
              </div>
          </Button>
        }

        {
          !isDivergent && priceString &&
          <div>{ priceString }</div>
        }

        {
          isDivergent &&
          <div style={{color: 'red', textAlign: 'center'}}>Your account has users with varying options.  If you'd like to add {AppConstants.providerNames['my_lot']}, please give us a call at {AppConstants.phoneNumber} and we'd be happy to help.</div>
        }

        {
          isShowingUpsellModal &&
          <OfferingUpsellModal
            logoWidth={200}
            logoHeight={33}
            offering={{sku: AppConstants.skuMappings['my_lot'], provider_key: 'my_lot'}}
            onDone={() => {
                this.setState({ isShowingUpsellModal: false})
                if (this.props.onDone) {
                  this.props.onDone()
                }
              }
            }
            onPurchase={() => {
              this.setState({ isShowingUpsellModal: false })
              window.location.reload()
            }} />
        }
  
      </div>
    )
  }
}
